import React from "react";
import { GrFormNextLink } from "react-icons/gr";
import { FaAnglesDown } from "react-icons/fa6";
import Navbar from "../components/Navbar";

const ScrollIndicator = () => {
	return (
		<div className="scroll-indicator absolute bottom-6 lg:bottom-4 2xl:bottom-12 left-1/2 transform -translate-x-1/2 w-6 h-6  animate-bounce">
			<FaAnglesDown className="w-6 h-6 text-gray-900 dark:text-white" />
		</div>
	);
};

function Herosection({ homeRef, aboutRef, productsRef, contactRef }) {
	return (
		<div className="relative h-dvh" ref={homeRef}>
			<Navbar
				aboutRef={aboutRef}
				productsRef={productsRef}
				contactRef={contactRef}
				homeRef={homeRef}
			/>
			<section className=" flex items-center justify-center w-full h-full -mt-16">
				<div className="py-8 px-4  text-center lg:py-12 lg:px-12">
					<h1 className=" mb-0 font-extrabold  text-gray-900 text-[50px] md:text-[90px] lg:text-[148px] dark:text-white">
						Your friends
					</h1>
					<div className=" text-center ">
						<div className=" uppercase font-black text-[36px] md:text-[50px] ">
							are waiting on
						</div>
						<div className="text-[70px] md:text-61xl uppercase font-black text-transparent text-center hero_section_title">
							Upstreet
						</div>
					</div>
					<div className="flex flex-col mt-8 items-center justify-center">
						<a
							href="https://upstreet.ai/adventure"
							target="_blank"
							className=" no-underline flex items-center justify-center w-[70%] md:w-[50%] lg:w-20% h-12 nav_item  text-white try_chat_btn"
						>
							<span className="skew-x-[20deg]">Try Chat</span>
							<GrFormNextLink className="skew-x-[20deg]" />
						</a>
					</div>
					<ScrollIndicator />
				</div>
			</section>
		</div>
	);
}

export default Herosection;

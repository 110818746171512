import React from "react";
import {
	FaFacebookF,
	FaInstagram,
	FaTwitter,
	FaLinkedinIn,
	FaDribbble,
} from "react-icons/fa";

function Socials() {
	// Function to navigate to a URL
	const navigateToURL = (url) => {
		window.open(url, "_blank");
	};

	return (
		<div>
			<div className="hidden lg:flex  items-center justify-around gap-5">
				<FaFacebookF
					className="w-[30px] h-[30px] text-white cursor-pointer"
					onClick={() => navigateToURL("https://facebook.com/upstreetAI")}
				/>
				<FaInstagram
					className="w-[30px] h-[30px] text-white cursor-pointer"
					onClick={() => navigateToURL("https://instagram.com/upstreetAI")}
				/>
				<FaTwitter
					className="w-[30px] h-[30px] text-white cursor-pointer"
					onClick={() => navigateToURL("https://twitter.com/upstreetAI")}
				/>
				<FaLinkedinIn
					className="w-[30px] h-[30px] text-white cursor-pointer"
					onClick={() =>
						navigateToURL("https://linkedin.com/company/upstreetAI")
					}
				/>
				<FaDribbble
					className="w-[30px] h-[30px] text-white cursor-pointer"
					onClick={() => navigateToURL("https://blog.upstreet.ai")}
				/>
			</div>
		</div>
	);
}

export default Socials;

import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function CareersPage({ aboutRef, productsRef, contactRef, homeRef }) {
	return (
		<div className="relative text-white">
			<Navbar
				aboutRef={aboutRef}
				productsRef={productsRef}
				contactRef={contactRef}
				homeRef={homeRef}
			/>
			<div className="absolute top-0 right-0 rounded-[50%] bg-aqua [filter:blur(892.27px)] opacity-50 w-[500px] h-[500px] " />
			<div class="flex items-center justify-center w-full h-full md:h-dvh">
				<div class="text-center">
					<header class="mb-8">
						<h1 class="text-6xl font-bold">Work with us</h1>
					</header>
					<section class="mb-8 px-4 text-center">
						<p class="mb-4 w-full md:w-[60%] mx-auto text-lg tracking-wide">
							We're glad you're interested in joining our team! At UpStreet.ai,
							we're always looking for talented individuals who are passionate
							about making a difference. UpstreetAI is a groundbreaking platform
							revolutionizing the gaming industry with its browser-based game
							engine, supporting an infinite procedurally generated world for an
							unparalleled gaming experience. At the forefront of technology,
							our AI-powered NPCs and the ability for users to connect their own
							agents showcase the innovative approach we bring to gaming. Join
							us at UpstreetAI, where creativity meets technology, and together,
							we shape the future of gaming.
						</p>
						<p class="text-lg">
							If you're interested in any of the positions listed below or want
							to inquire about other opportunities, please reach out to us at
							<a
								href="mailto:careers@upstreet.ai"
								class="text-white bg-[#161616] px-2 py-1 rounded-md hover:bg-[#363636] hover:text-white transition-all duration-300 ease-in-out"
							>
								careers@upstreet.ai
							</a>
							.
						</p>
						<div className="flex  mt-8 items-center justify-center cursor-pointer gap-6">
							<a
								href="#"
								class="no-underline flex items-center justify-center w-[50%] md:w-[20%] h-12 nav_item text-white bg-[#161616] px-2 py-1 rounded-md hover:bg-[#363636] hover:text-white transition-all duration-300 ease-in-out"
							>
								<span class="skew-x-[20deg]">Apply for open roles</span>
							</a>
							<a
								href="/careers/our-team"
								class="no-underline flex items-center justify-center w-[50%] md:w-[20%] h-12 nav_item text-white bg-[#161616] px-2 py-1 rounded-md hover:bg-[#363636] hover:text-white transition-all duration-300 ease-in-out"
							>
								<span class="skew-x-[20deg]">Our Team</span>
							</a>
						</div>
					</section>
				</div>
			</div>

			<div className="absolute bottom-[200px] -left-[400px] rounded-[50%] bg-gray-300 [filter:blur(600px)] w-[500px] h-[500px] " />
			<Footer />
		</div>
	);
}

export default CareersPage;

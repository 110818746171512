import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { FaGithub, FaXTwitter, FaLinkedinIn } from "react-icons/fa6";

function OurTeam({ aboutRef, productsRef, contactRef, homeRef }) {
	const team = [
		{
			name: "avaer",
			role: "CEO",
			img: "Avaer.png",
			skills: "3D, Web3, XR, Graphics, AI Development",
			github: "",
			linkedin: "",
			twitter: "",
		},
		{
			name: "aqeel",
			role: "COO",
			img: "aqeel.jpg",
			skills:
				"Operations Management, Strategy, Product Development, Business Development, Partnerships",
			github: "",
			linkedin: "",
			twitter: "",
		},
		{
			name: "cjft",
			role: "CTO",
			img: "chris.jpg",
			skills: "Full-stack Typescript, React-three-fiber, Web3, AI",
			github: "",
			linkedin: "",
			twitter: "",
		},
		{
			name: "alextitonis",
			role: "Engineering Manager",
			img: "alext.jpg",
			skills: "Full-stack, Node.js/TS, C#, C++, Python",
			github: "",
			linkedin: "",
			twitter: "",
		},
		{
			name: "bruhvatar",
			role: "AI Engineer",
			img: "bruhvatar.png",
			skills:
				"AI research, AI-backend to app-frontend bridging, AI service app testing",
			github: "",
			linkedin: "",
			twitter: "",
		},
		{
			name: "zeref",
			role: "Backend Engineer",
			img: "aze.jpeg",
			skills:
				"Nodejs/TS, Dockerization, Kubernetes, Python, Scaling Infrastructure, Tech Costs Management",
			github: "",
			linkedin: "",
			twitter: "",
		},
		{
			name: "emmyn",
			role: "Frontend Developer",
			img: "Emmyn.png",
			skills: "ReactJs/Nextjs, Nodejs/TS, Ruby",
			github: "",
			linkedin: "",
			twitter: "",
		},
		{
			name: "nebula",
			role: "Operations / People / Finance",
			img: "mariana.png",
			skills: "Strategic Planning, Problem Solving, Curiosity",
			github: "",
			linkedin: "",
			twitter: "",
		},
		{
			name: "Matt",
			role: "Software Engineer",
			img: "matt.jpg",
			skills: "Javascript, Typescript, React.js, Three.js",
			github: "",
			linkedin: "",
			twitter: "",
		},
		{
			name: "tcm",
			role: "Software Engineer",
			img: "tmc.png",
			skills: "React.js, Three.js, C++, WebGL",
			github: "",
			linkedin: "",
			twitter: "",
		},
		// {
		// 	name: "Thegen",
		// 	role: "Community Manager/Token Founder",
		// 	img: "aze.jpg",
		// 	skills: "NFT Research, React.js, Python",
		// 	github: "",
		// 	linkedin: "",
		// 	twitter: "",
		// },
	];

	return (
		<div className="relative text-white font-inter">
			<Navbar
				aboutRef={aboutRef}
				productsRef={productsRef}
				contactRef={contactRef}
				homeRef={homeRef}
			/>

			<div class="container mx-auto py-8 z-50">
				<div class="flex flex-col justify-center text-center gap-4 mb-8 p-4 ">
					<div className="text-center text-[50px] font-extrabold uppercase ">
						<span className="w-full">
							<span>OUR</span> <span className="hero_section_title">team</span>
						</span>
					</div>

					<div class="text-gray-200 text-3xl md:text-4xl font-bold">
						Introducing the UpstreetAI Team, Where Passion Fuels Innovation!
					</div>

					<div class="text-gray-500 md:text-lg">
						This is the UpStreet.ai team— a vibrant group of enthusiastic
						individuals dedicated to revolutionizing the gaming industry. With
						smiles on our faces and determination in our hearts, we work
						tirelessly to empower your gaming experiences. Join us in shaping
						the future of gaming!
					</div>
				</div>

				<div class="flex items-center justify-center flex-wrap gap-4 p-4">
					{team.map((member, index) => (
						<div class="card" id={index}>
							<img
								src={
									member.img
										? `${process.env.PUBLIC_URL + "../" + member.img}`
										: "https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
								}
								class="w-24 h-24 rounded-full object-cover transition duration-200 hover:scale-110"
							/>

							<div class="text-white text-lg font-bold">{member.name}</div>

							<div class="text-green-200">{member.role}</div>

							<div class="text-gray-600 text-center text-white">
								{member.skills}
							</div>

							<div class="flex items-center justify-center gap-3 mt-2 w-auto h-5 text-gray-600">
								<FaGithub className="w-5 h-5 cursor-pointer transition duration-200 hover:text-gray-400" />
								<FaXTwitter className="w-5 h-5 cursor-pointer transition duration-200 hover:text-gray-400" />
								<FaLinkedinIn className="w-5 h-5 cursor-pointer transition duration-200 hover:text-gray-400" />
							</div>
						</div>
					))}
				</div>
			</div>
			<div className="absolute bottom-[200px] -left-[400px] rounded-[50%] bg-gray-300 [filter:blur(600px)] w-[500px] h-[500px] " />
			<Footer />
		</div>
	);
}

export default OurTeam;

import React from "react";
import { FaCaretDown } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa6";

function Contact({ contactRef }) {
	const handlediscord = () => {
		window.open("https://discord.com/invite/upstreet", "_blank");
	};
	const handleTwitter = () => {
		window.open("https://twitter.com/upstreetAI", "_blank");
	};
	return (
		<div className="contact" id="contact">
			<div
				ref={contactRef}
				className="relative h-[600px] lg:h-[1080px] overflow-hidden text-29xl "
			>
				<div className="relative w-full h-full px-2">
					<div className="absolute -bottom-[200px] -right-[200px] rounded-[50%] bg-darkslateblue-100 w-[404px] h-[404px]" />
					<div className="absolute top-[895px] left-[-62px] rounded-[50%] box-border w-[479px] h-[479px] border-[1px] border-solid border-white" />
					<div className="block lg:grid grid-cols-2 gap-0 w-full h-full">
						<img
							src={`${process.env.PUBLIC_URL + "/round-cube3@2x.png"}`}
							className="absolute top-[896px] left-[-122px] w-[539px] h-[477.75px] object-cover"
							alt=""
						/>
						<div className="hidden lg:block z-20  w-full h-full relative">
							<img
								src={`${
									process.env.PUBLIC_URL +
									"/jeffseid2001-generate-a-charismatic-and-adventurous-anime-girl--05bac14b17ea48ee9f7b4dfc968b915a-1@2x.png"
								}`}
								className=" absolute right-[-100px] bottom-0 w-[950px] h-[950px] object-cover"
								alt=""
							/>
						</div>
						<div className="w-full h-full relative">
							<div className=" absolute  bottom-20 w-full p-8 lg:w-[85%] h-[400px] rounded-[13px] [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.04),_rgba(255,_255,_255,_0.1))] [backdrop-filter:blur(9px)] box-border border-[0.4px] border-solid border-white ">
								<div className="flex flex-col items-start justify-start gap-[82px] ">
									<div className="flex flex-col items-start justify-start gap-[24px]">
										<div className="relative leading-[57px] font-black">
											<span>{`Follow us into the `}</span>
											<span className="newsletter_text">Metaverse</span>
										</div>
										{/* <div className="relative text-xl leading-[26px] font-montserrat whitespace-pre-wrap ">{`Subscribe to our email & get updates right  your inbox`}</div> */}
									</div>
									<div className="flex items-center justify-center w-full gap-12">
										<div
											onClick={() => handleTwitter()}
											className="flex items-center justify-center border-solid border border-white rounded-full h-[50px] w-[50px] cursor-pointer"
										>
											<FaXTwitter className="w-6 h-6" />
										</div>
										<div
											onClick={() => handlediscord()}
											className="flex items-center justify-center border-solid border border-white rounded-full h-[50px] w-[50px] cursor-pointer"
										>
											<FaDiscord className="w-6 h-6" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="absolute top-[140px] left-[247px] w-[613px] h-[1312px]" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default Contact;

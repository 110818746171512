import React from "react";

function NoPage() {
	return (
		<div
			className="text-white "
			style={{ textAlign: "center", marginTop: "50px" }}
		>
			<h1 style={{ fontSize: "24px", fontWeight: "bold" }}>Page Not Found</h1>
			<p style={{ fontSize: "16px", marginTop: "10px" }}>
				The requested page could not be found.
			</p>
		</div>
	);
}

export default NoPage;

import React from "react";
import { FaFacebookF, FaTwitter, FaGithub, FaInstagram } from "react-icons/fa";

function Footer() {
	const navigateToURL = (url) => {
		window.open(url, "_blank");
	};
	return (
		<div className=" w-full lg:h-[800px] font-inter">
			<div className="w-full h-full relative ">
				<div className=" w-full h-20 text-11xl flex justify-center footer_glassmorphism">
					<div className="flex flex-row items-start justify-center gap-[51px] my-auto ">
						<div className="flex items-center justify-center gap-4">
							<img
								className=" w-3 h-3 lg:w-9 lg:h-9 object-cover"
								alt=""
								src={`${process.env.PUBLIC_URL + "/Star1.png"}`}
							/>
							<p className="text-[9px] lg:text-xl  uppercase">Future Realms</p>
						</div>
						<div className="flex items-center justify-center gap-4 ">
							<img
								className=" w-3 h-3 lg:w-9 lg:h-9 object-cover"
								alt=""
								src={`${process.env.PUBLIC_URL + "/Star1.png"}`}
							/>
							<p className="text-[9px] lg:text-xl  uppercase">
								Action - packed
							</p>
						</div>
						<div className="flex items-center justify-center gap-4">
							<img
								className=" w-3 h-3 lg:w-9 lg:h-9 object-cover"
								alt=""
								src={`${process.env.PUBLIC_URL + "/Star1.png"}`}
							/>
							<p className="text-[9px] lg:text-xl  uppercase">mind - bending</p>
						</div>
						<div className=" hidden  md:flex items-center justify-center gap-4">
							<img
								className=" w-3 h-3 lg:w-9 lg:h-9 object-cover"
								alt=""
								src={`${process.env.PUBLIC_URL + "/Star1.png"}`}
							/>
							<p className="text-[8px] lg:text-xl  uppercase">
								Gaming Excellence
							</p>
						</div>
					</div>
				</div>
				<div className="w-full  text-lg  px-4">
					<div className="mx-auto max-w-screen-xl px-4 pb-6 pt-16 sm:px-6 lg:px-8 lg:pt-24">
						<div className="grid grid-cols-1 gap-0 lg:gap-8 lg:grid-cols-4">
							<div className="">
								<h6 className="m-0 mt-4 text-[40px] font-bold uppercase md:justify-start">
									UPSTREET
								</h6>
								<p>
									Follow us on Twitter for the latest updates, join the vibrant
									community on Discord, and explore our projects on Github. For
									career opportunities and partnerships, get in touch with us.
								</p>
							</div>

							<div className="">
								<h6 className="mb-4 flex justify-start font-semibold uppercase text-lg">
									About
								</h6>
								<p className="mb-4">
									<a
										href="#!"
										className="text-neutral-600 dark:text-neutral-200 no-underline text-base"
									>
										Upstreet
									</a>
								</p>
								<p className="mb-4">
									<a
										href="#!"
										className="text-neutral-600 dark:text-neutral-200 no-underline text-base"
									>
										Apps & Games
									</a>
								</p>
								<p className="mb-4">
									<a
										href="#!"
										className="text-neutral-600 dark:text-neutral-200 no-underline text-base "
									>
										Features
									</a>
								</p>
							</div>

							<div className="">
								<h6 className="mb-4 flex justify-start font-semibold uppercase text-lg ">
									Products
								</h6>
								<p className="mb-4">
									<a
										href="#!"
										className="text-neutral-600 dark:text-neutral-200 no-underline text-base"
									>
										Upstreet TV
									</a>
								</p>
								<p className="mb-4">
									<a
										href="#!"
										className="text-neutral-600 dark:text-neutral-200 no-underline text-base"
									>
										Engine
									</a>
								</p>
								<p className="mb-4">
									<a
										href="#!"
										className="text-neutral-600 dark:text-neutral-200 no-underline text-base"
									>
										Chat
									</a>
								</p>
							</div>
							<div>
								<h6 className="mb-4 flex justify-start font-semibold uppercase text-lg">
									Contact
								</h6>
								<p className="mb-4">
									<a
										href="https://discord.gg/KQkTUEmu"
										className="text-neutral-600 dark:text-neutral-200 no-underline text-base"
									>
										Discord
									</a>
								</p>
								<p className="mb-4">
									<a
										href="#!"
										className="text-neutral-600 dark:text-neutral-200 no-underline text-base"
									>
										Newsletter
									</a>
								</p>
								<p className="mb-4">
									<a
										href="#!"
										className="text-neutral-600 dark:text-neutral-200 no-underline text-base"
									>
										Terms & Conditions
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>
				{/* <div className="flex items-center justify-center gap-2  w-[90%] lg:w-full  text-[14px] mb-6 px-4 lg:px-0">
					<img
						className=" max-w-full overflow-hidden max-h-full"
						alt=""
						src={`${process.env.PUBLIC_URL + "/twitch.png"}`}
					/>
					<img
						className="  max-w-full overflow-hidden max-h-full"
						alt=""
						src={`${process.env.PUBLIC_URL + "/roblox.png"}`}
					/>
					<img
						className=" max-w-full overflow-hidden max-h-full"
						alt=""
						src="/asus.png"
					/>
					<img
						className=" max-w-full overflow-hidden max-h-full"
						alt=""
						src={`${process.env.PUBLIC_URL + "/group.svg"}`}
					/>
					<img
						className=" max-w-full overflow-hidden max-h-full"
						alt=""
						src={`${process.env.PUBLIC_URL + "/group-1000001744.svg"}`}
					/>
				</div> */}
				<div className="flex flex-col lg:flex-row items-center justify-center gap-4 lg:gap-[50px]   text-[14px] mb-16 lg:mb-0">
					<div className="flex  items-center justify-end gap-4  ">
						<div className="flex items-center justify-center border-solid border border-white rounded-full h-[36px] w-[36px]">
							<FaTwitter
								className="w-6 h-6"
								onClick={() => navigateToURL("https://twitter.com/upstreetAI")}
							/>
						</div>
						<div className="flex items-center justify-center border-solid border border-white rounded-full h-[36px] w-[36px]">
							<FaFacebookF
								className="w-6 h-6"
								onClick={() => navigateToURL("https://facebook.com/upstreetAI")}
							/>
						</div>
						<div className="flex items-center justify-center border-solid border border-white rounded-full h-[36px] w-[36px]">
							<FaInstagram
								className="w-6 h-6"
								onClick={() =>
									navigateToURL("https://instagram.com/upstreetAI")
								}
							/>
						</div>
						<div className="flex items-center justify-center border-solid border border-white rounded-full h-[36px] w-[36px]">
							<FaGithub
								className="w-6 h-6"
								onClick={() => navigateToURL("https://github.com/UpstreetAI")}
							/>
						</div>
					</div>
					<div className="text-center">
						© Copyright 2024, All Rights Reserved by Upstreet.
					</div>
				</div>
				<div className="absolute w-full -bottom-20 lg:-bottom-4 ">
					<img
						className="w-full object-cover"
						alt=""
						src={`${process.env.PUBLIC_URL + "../footer.png"}`}
					/>
				</div>
			</div>
		</div>
	);
}

export default Footer;

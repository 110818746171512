import React from "react";

function AboutUs({ aboutRef }) {
	return (
		<div
			ref={aboutRef}
			className="relative w-full h-full lg:h-screen text-center flex items-center px-4 md:px-10 md:mt-10 "
		>
			<div className="flex flex-col lg:flex-row items-center justify-between w-full ">
				<div className="w-full">
					<div className="text-left text-[50px] font-extrabold uppercase ">
						<span className="w-full">
							<span>ABOUT</span> <span className="hero_section_title">US</span>
						</span>
					</div>
					<p className="text-5xl flex text-left items-center justify-center w-[380px] lg:w-[552px] ">
						Freedom is our core mission. Freedom to be yourself. Freedom to say
						what you believe. Freedom to transact. We are the keepers of an
						uncompromising vision of a truly open, decentralized and free
						internet.
					</p>
				</div>
				<div className="relative z-20">
					<img
						className="w-full lg:w-[70%] object-cover"
						alt=""
						src={`${process.env.PUBLIC_URL + "/chating.png"}`}
					/>
				</div>
			</div>
			<div className="hidden lg:block absolute top-[11px] right-[0px] rounded-[50%] bg-aqua filter blur-[892.27px] w-[50vw] h-[50vw] max-w-[521px] max-h-[521px] z-10" />
		</div>
	);
}

export default AboutUs;

import React, { useState } from "react";
import { CiMenuBurger } from "react-icons/ci";
import Socials from "./Socials";
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { IoCloseOutline } from "react-icons/io5";
import {
	FaFacebookF,
	FaInstagram,
	FaTwitter,
	FaLinkedinIn,
	FaDribbble,
} from "react-icons/fa";

// Configure GSAP to use ScrollToPlugin
gsap.registerPlugin(ScrollToPlugin);

function Navbar({ homeRef, aboutRef, productsRef, contactRef }) {
	const [showMobileMenu, setShowMobileMenu] = useState(false);

	const toggleMobileMenu = () => {
		setShowMobileMenu(!showMobileMenu);
	};
	const navigateToURL = (url) => {
		window.open(url, "_blank");
	};

	const handleClickMenuItem = (ref) => {
		setShowMobileMenu(false);
		if (window.location.pathname !== "/") {
			window.location.href = "/";
			const targetPosition = ref.current.offsetTop;
			gsap.to(window, {
				duration: 1,
				scrollTo: { y: targetPosition },
				ease: "power3.inOut",
			});
		} else {
			const targetPosition = ref.current.offsetTop;
			gsap.to(window, {
				duration: 1,
				scrollTo: { y: targetPosition },
				ease: "power3.inOut",
			});
		}
	};

	return (
		<>
			<section className="relative mx-auto mt-4 w-full">
				<nav className="flex items-center  text-white w-full px-5 xl:px-12 ">
					<div className="flex flex-row items-center justify-between p-3 md:p-1 w-full lg:w-fit">
						<a
							href="/"
							class="flex text-3xl text-white font-medium mb-4 md:mb-0 w-[80px] h-[80px]"
						>
							<img
								src={`${process.env.PUBLIC_URL + "/asset-6-1@2x.png"}`}
								alt="logo"
								className="w-full h-full object-cover"
							/>
						</a>
						<div className="">
							<CiMenuBurger
								className="text-[50px] cursor-pointer text-white self-end  lg:hidden"
								onClick={toggleMobileMenu}
							/>
						</div>
					</div>

					{/* <!-- Nav Links --> */}
					<div className="hidden lg:flex px-4 mx-auto font-semibold font-heading space-x-12">
						<div className="" onClick={() => handleClickMenuItem(homeRef)}>
							<div className="flex items-center justify-center lg:w-[150px] xl:w-30 h-12 nav_item">
								<p className="font-extrabold uppercase skew-x-[20deg]">Home</p>
							</div>
						</div>
						<div className="" onClick={() => handleClickMenuItem(aboutRef)}>
							<div className="flex items-center justify-center lg:w-[150px] xl:w-30 h-12 nav_item">
								<p className="font-extrabold uppercase skew-x-[20deg]">About</p>
							</div>
						</div>
						<div className="" onClick={() => handleClickMenuItem(productsRef)}>
							<div className="flex items-center justify-center lg:w-[150px] xl:w-30 h-12 nav_item">
								<p className="font-extrabold uppercase skew-x-[20deg]">
									Products
								</p>
							</div>
						</div>
						<div className="" onClick={() => handleClickMenuItem(contactRef)}>
							<div className="flex items-center justify-center lg:w-[150px] xl:w-30 h-12 nav_item">
								<p className="font-extrabold uppercase skew-x-[20deg]">
									Contact us
								</p>
							</div>
						</div>
					</div>
					<Socials />
				</nav>
				{showMobileMenu && (
					<div
						className={`mobile_menu bg-black ${
							showMobileMenu ? "show" : ""
						} lg:hidden fixed top-0 right-0 w-full text-lg  p-4 z-[99]`}
					>
						<div className="flex items-center w-full justify-between">
							<img
								src={`${process.env.PUBLIC_URL + "/asset-6-1@2x.png"}`}
								alt="logo"
								className="w-[80px] h-[80px] object-cover"
							/>
							<IoCloseOutline
								className="text-4xl cursor-pointer"
								onClick={toggleMobileMenu}
							/>
						</div>
						<div className="flex flex-col  items-center mt-12">
							<p
								className="font-extrabold uppercase mb-4 cursor-pointer"
								onClick={() => handleClickMenuItem(homeRef)}
							>
								Home
							</p>
							<p
								className="font-extrabold uppercase mb-4 cursor-pointer"
								onClick={() => handleClickMenuItem(aboutRef)}
							>
								About
							</p>
							<p
								className="font-extrabold uppercase mb-4 cursor-pointer"
								onClick={() => handleClickMenuItem(productsRef)}
							>
								Products
							</p>
							<p
								className="font-extrabold uppercase mb-4 cursor-pointer"
								onClick={() => handleClickMenuItem(contactRef)}
							>
								Contact us
							</p>
						</div>
						<div className=" w-full">
							<div className="flex  items-center justify-around gap-5 mt-8">
								<FaFacebookF
									className="w-[30px] h-[30px] text-white cursor-pointer"
									onClick={() =>
										navigateToURL("https://facebook.com/upstreetAI")
									}
								/>
								<FaInstagram
									className="w-[30px] h-[30px] text-white cursor-pointer"
									onClick={() =>
										navigateToURL("https://instagram.com/upstreetAI")
									}
								/>
								<FaTwitter
									className="w-[30px] h-[30px] text-white cursor-pointer"
									onClick={() =>
										navigateToURL("https://twitter.com/upstreetAI")
									}
								/>
								<FaLinkedinIn
									className="w-[30px] h-[30px] text-white cursor-pointer"
									onClick={() =>
										navigateToURL("https://linkedin.com/company/upstreetAI")
									}
								/>
								<FaDribbble
									className="w-[30px] h-[30px] text-white cursor-pointer"
									onClick={() => navigateToURL("https://blog.upstreet.ai")}
								/>
							</div>
						</div>
					</div>
				)}
			</section>
		</>
	);
}

export default Navbar;

import ReactDOM from "react-dom/client";
import React, { useRef } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NoPage from "./pages/NoPage";
import Home from "./pages/Home";
import Careers from "./pages/Careers";
import OurTeam from "./pages/OurTeam";

export default function App() {
	const homeRef = useRef(null);
	const aboutRef = useRef(null);
	const productsRef = useRef(null);
	const contactRef = useRef(null);
	return (
		<BrowserRouter>
			<Routes>
				<Route
					index
					element={
						<Home
							aboutRef={aboutRef}
							productsRef={productsRef}
							contactRef={contactRef}
							homeRef={homeRef}
						/>
					}
				/>
				<Route
					path="careers"
					element={
						<Careers
							aboutRef={aboutRef}
							productsRef={productsRef}
							contactRef={contactRef}
							homeRef={homeRef}
						/>
					}
				/>
				<Route
					path="/careers/our-team"
					element={
						<OurTeam
							aboutRef={aboutRef}
							productsRef={productsRef}
							contactRef={contactRef}
							homeRef={homeRef}
						/>
					}
				/>
				<Route path="*" element={<NoPage />} />
			</Routes>
		</BrowserRouter>
	);
}

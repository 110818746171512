import React from "react";

function OurProducts({ productsRef }) {
	const handleLiveDemo = () => {
		window.open("https://upstreet.ai/adventure", "_blank");
	};

	return (
		<div
			className="relative w- h-full  lg:h-screen   flex flex-col items-center justify-center mb-12 "
			ref={productsRef}
		>
			<div className="mb-12 w-full">
				<div className="grid lg:block text-center text-[50px] font-extrabold uppercase tracking-wide w-full">
					Upstreet
					<span className="hero_section_title mx-4">Products</span>
					Suite
				</div>
				<p className="text-center w-full">
					Discover the future of gaming with Upstreet's innovative products
				</p>
			</div>
			<div className="flex justify-center items-center w-full">
				<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-2">
					<div className="shadow-[0px_4px_16px_#06f0f6] w-[95%] md:w-[350px] lg:w-[397px]  h-[530px] text-lg font-lato glassmorphism_card">
						<div className="flex flex-col items-center justify-between w-full h-full ">
							<img
								className=" rounded-3xs w-[90%] object-cover  mt-6"
								alt=""
								src={`${process.env.PUBLIC_URL + "/rectangle-11@2x.png"}`}
							/>
							<div className="text-5xl   capitalize font-medium">
								UPSTREET CHAT
							</div>
							<div className="flex items-center gap-4 w-full px-4 ">
								<img
									className=" rounded-[50%] w-[53px] h-[53px] object-cover "
									alt=""
									src={`${process.env.PUBLIC_URL + "/ellipse-5@2x.png"}`}
								/>
								<div className=" text-xs   capitalize  flex items-center w-[279px] h-[43px]">
									Find your people through AI-powered avatar chat.No downloads,
									completely free.Try it now!
								</div>
							</div>
							<div
								className="  uppercase font-extrabold flex items-center justify-center w-full mb-5"
								onClick={() => handleLiveDemo()}
							>
								<button className="explore_btn text-white border-none font-bold w-[90%] h-[40px]">
									<p className="skew-x-[20deg]">Live Demo</p>
								</button>
							</div>
						</div>
					</div>

					<div className="relative shadow-[0px_4px_16px_#06f0f6] w-[95%] md:w-[350px] lg:w-[397px]  h-[530px] text-lg glassmorphism_card">
						<div className="absolute top-8 right-8 bg-[#424243] text-white px-2 py-1 rounded-md">
							Coming Soon
						</div>
						<div className="flex flex-col items-center justify-between w-full h-full ">
							<img
								className=" rounded-3xs w-[90%] object-cover  mt-6"
								alt=""
								src={`${process.env.PUBLIC_URL + "/rectangle-11@2x.png"}`}
							/>
							<div className="text-5xl   capitalize font-medium">
								UPSTREET TV
							</div>
							<div className="flex items-center gap-4 w-full px-4 ">
								<img
									className=" rounded-[50%] w-[53px] h-[53px] object-cover "
									alt=""
									src={`${process.env.PUBLIC_URL + "/ellipse-5@2x.png"}`}
								/>
								<div className=" text-xs   capitalize  flex items-center w-[279px] h-[43px]">
									Create your own TV shows and broadcast them to the
									world.Coming Spring 2024.
								</div>
							</div>
							<div
								className="  uppercase font-extrabold flex items-center justify-center w-full mb-5"
								onClick={() => handleLiveDemo()}
							>
								<button className="explore_btn text-white border-none font-bold w-[90%] h-[40px]">
									<p className="skew-x-[20deg]">Live Demo</p>
								</button>
							</div>
						</div>
					</div>

					<div className="relative shadow-[0px_4px_16px_#06f0f6]   glassmorphism_card w-[95%] md:w-[350px] lg:w-[397px]  h-[530px] ">
						<div className="absolute top-8 right-8 bg-[#424243] text-white px-2 py-1 rounded-md">
							Coming Soon
						</div>
						<div className="flex flex-col items-center justify-between w-full h-full ">
							<img
								className=" rounded-3xs w-[90%] object-cover  mt-6"
								alt=""
								src={`${process.env.PUBLIC_URL + "/rectangle-13@2x.png"}`}
							/>
							<div className=" text-[27.93px]   capitalize font-medium py-3">
								UPSTREET ENGINE
							</div>
							<div className="flex items-center gap-4 px-4 w-full">
								<img
									className=" rounded-[50%] w-[40px] h-[40px] 2xl:w-[53px] 2xl:h-[53px] object-cover"
									alt=""
									src={`${process.env.PUBLIC_URL + "/ellipse-5@2x.png"}`}
								/>
								<div className=" text-[10px] 2xl:text-[13.96px]   capitalize font-poppins flex items-center  h-[100.54px] 2xl:w-[324.68px] 2xl:h-[117.54px]">
									Our web-native experiences are powered by our own Upstreet
									Engine, which leverages the latest technologies to deliver to
									nearly every device without app store restrictions. If you
									would like to discuss powering your next project with Upstreet
									Engine, get in touch.
								</div>
							</div>
							<div
								className="  uppercase font-extrabold flex items-center justify-center w-full mb-5"
								onClick={() => handleLiveDemo()}
							>
								<button className="explore_btn text-white border-none font-bold w-[90%] h-[40px] ">
									<p className="skew-x-[20deg]">Live Demo</p>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default OurProducts;

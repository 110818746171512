import React from "react";

function Explore() {
	return (
		<div>
			<div className=" w-full h-20 text-11xl flex justify-center footer_glassmorphism">
				<div className="flex flex-row items-start justify-center gap-[51px] my-auto ">
					<div className="flex items-center justify-center gap-4">
						<img
							className=" w-3 h-3 lg:w-9 lg:h-9 object-cover"
							alt=""
							src={`${process.env.PUBLIC_URL + "/Star1.png"}`}
						/>
						<p className="text-[9px] lg:text-xl  uppercase">
							Infinite Discoveries
						</p>
					</div>
					<div className="flex items-center justify-center gap-4 ">
						<img
							className=" w-3 h-3 lg:w-9 lg:h-9 object-cover"
							alt=""
							src={`${process.env.PUBLIC_URL + "/Star1.png"}`}
						/>
						<p className="text-[9px] lg:text-xl  uppercase">Create. Thrive</p>
					</div>
					<div className="flex items-center justify-center gap-4">
						<img
							className=" w-3 h-3 lg:w-9 lg:h-9 object-cover"
							alt=""
							src={`${process.env.PUBLIC_URL + "/Star1.png"}`}
						/>
						<p className="text-[9px] lg:text-xl  uppercase">
							Gaming Excellence
						</p>
					</div>
					<div className=" hidden  md:flex items-center justify-center gap-4">
						<img
							className=" w-3 h-3 lg:w-9 lg:h-9 object-cover"
							alt=""
							src={`${process.env.PUBLIC_URL + "/Star1.png"}`}
						/>
						<p className="text-[8px] lg:text-xl  uppercase">Future Realms</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Explore;

import React from "react";

function JoinTeam() {
	return (
		<div className="relative  h-full md:h-screen mt-20" id="products">
			<div className="w-[98%] h-full lg:text-29xl  flex items-center justify-center">
				{/* <div className="absolute top-[400px] left-[0px] rounded-[50%] bg-aqua [filter:blur(892.27px)] w-[521px] h-[521px] z-0" /> */}

				<div className="rounded-3xs w-[86%]  md:w-11/12  2xl:w-9/12 4xl:w-6/12  h-[613px] md:h-[426px] lg:h-[663px] glassmorphism ">
					<div className="relative grid grid-cols-1 lg:grid-cols-2 gap-4 h-full w-full">
						<img
							className="hidden lg:block absolute -top-[82px] -left-[115px] w-[745px] h-[745px] object-cover z-50"
							alt=""
							src={`${process.env.PUBLIC_URL + "/animeboy.png"}`}
						/>

						<div className="lg:absolute lg:top-2/4 lg:-translate-x-2/4 lg:-translate-y-[42%] lg:left-[57%]  text-center lg:text-left mt-6 lg:mt-0">
							<div className=" my-4 md:my-0 uppercase font-black flex items-center lg:w-[531px]">
								<span className="w-full px-4 md:px-0">
									<p className="[margin-block-start:0] [margin-block-end:8.57px]">
										<span>{`Join the Upstreet `}</span>{" "}
										<span className="hero_section_title">Revolution</span>
									</p>
								</span>
							</div>
							<div className=" text-base   flex items-center lg:w-[463px] px-4 md:px-0">
								We are passionate about building a future internet for everyone.
								If that sounds interesting, we'd love to hear from you. Check
								out our careers page for current openings. If you don't see a
								job listing, send us a message anyway.
							</div>
							<div className="w-full flex justify-center items-center mt-12">
								<a
									href="/careers"
									className=" w-[80%] lg:w-[270px] h-[74px] text-xl about_section_play_btn"
								>
									<p className=" text-center  uppercase font-extrabold">
										JOIN THE TEAM
									</p>
								</a>
							</div>
						</div>
						<img
							className="absolute -bottom-[21px] -right-[37px] w-[180px] lg:w-[500px]  object-cover z-50"
							alt=""
							src={`${process.env.PUBLIC_URL + "/animegril2.png"}`}
						/>
					</div>
				</div>
			</div>
			<div className="absolute bottom-0 -left-[400px] rounded-[50%] bg-aqua [filter:blur(892.27px)] opacity-75 w-[521px] h-[521px] z-0" />
		</div>
	);
}

export default JoinTeam;

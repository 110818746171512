import React from "react";
import AboutUs from "../components/AboutUs";
import JoinTeam from "../components/JoinTeam";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import Herosection from "../components/Herosection";
import ChatShowCase from "../components/ChatShowCase";
import OurProducts from "../components/OurProducts";
import Explore from "../components/Explore";

function App({ aboutRef, productsRef, contactRef, homeRef }) {
	return (
		<div className="relative text-xl text-white overflow-hidden">
			{/* <div className="absolute top-[10%] left-[50%] [background:linear-gradient(180deg,_#000,_rgba(0,_0,_0,_0))] w-screen h-[1920px] [transform:_rotate(-90deg)] [transform-origin:0_0] opacity-[0.3]" /> */}
			<div className="hidden md:block absolute top-[22px] left-[calc(50%_-_253px)] rounded-[50%] bg-gray-300 [filter:blur(600px)] w-[500px] h-[500px]" />
			<Herosection
				aboutRef={aboutRef}
				productsRef={productsRef}
				contactRef={contactRef}
				homeRef={homeRef}
			/>
			<ChatShowCase />
			<AboutUs aboutRef={aboutRef} />
			<div className="flex items-center justify-center w-full text-center h-full lg:h-[500px] py-8">
				<p className="text-[17px] w-full md:text-[50px] md:w-[60%] px-4 md:px-0">
					READY TO REDEFINE YOUR GAMING EXPERIENCE? CLICK 'EXPLORE NOW' AND STEP
					INTO THE FUTURE WITH UPSTREET
				</p>
			</div>

			<OurProducts productsRef={productsRef} />
			<Explore />
			<JoinTeam />
			<Contact contactRef={contactRef} />
			<div className="absolute bottom-[500px] -left-[400px] rounded-[50%] bg-gray-300 [filter:blur(600px)] w-[500px] h-[500px] " />
			<Footer />
		</div>
	);
}

export default App;

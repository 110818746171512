import React from "react";
import { GrFormNextLink } from "react-icons/gr";
import { TbDeviceVisionPro } from "react-icons/tb";
import { BsChatDots } from "react-icons/bs";
import { RiVoiceprintFill } from "react-icons/ri";

function ChatShowCase() {
	const handleChat = () => {
		window.open("https://upstreet.ai/adventure", "_blank");
	};
	return (
		<div className="relative h-screen w-full mb-10 md:mb-0">
			<div
				id="card-1"
				className="absolute top-0 left-[5%] rounded-xl bg-[#1A1B23] w-[200px] h-fit md:w-[250px] lg:w-[380px] flex flex-col items-center justify-start py-8 px-6 box-border gap-[32px_0px] text-center z-20"
			>
				<div className="self-stretch flex flex-col items-center justify-start gap-[16px_0px]">
					<div className="relative w-10 lg:w-20">
						<img
							className="w-full  object-cover"
							alt=""
							src={`${process.env.PUBLIC_URL + "/glass.svg"}`}
						/>
						<BsChatDots className="text-[25px] lg:text-[50px] absolute top-[25%] left-[20%]" />
					</div>
					<div className="self-stretch relative leading-[130%] font-extrabold">
						1:1 Chat
					</div>
					<div className="self-stretch relative text-base md:leading-[150%] text-black-black-60">
						<p className="m-0 text-[8px] md:text-inherit">
							Store your growing investments in our
						</p>
						<p className="m-0 text-[8px] md:text-inherit">
							non-custodial walletthat gives you access to a full suite of DeFi
							services in one place
						</p>
					</div>
				</div>
				<div className="rounded-3xs text-green-200 overflow-hidden flex flex-row items-center justify-center gap-[0px_6px] text-base text-mediumseagreen mb-4">
					<div
						onClick={() => handleChat()}
						className="rounded-3xs overflow-hidden flex flex-row items-center justify-center gap-[0px_6px] cursor-pointer"
					>
						<div className="relative leading-[100%] font-semibold">
							Try chat
						</div>
						<GrFormNextLink className="" />
					</div>
				</div>
			</div>
			<div
				id="card-2"
				className="absolute bottom-0 md:bottom-4 left-[1%] md:left-[5%] lg:-bottom-20 lg:left-[10%] rounded-xl bg-[#1A1B23] w-[200px] h-fit md:w-[250px] lg:w-[380px] flex flex-col items-center justify-start py-8 px-6 box-border gap-[32px_0px] text-center"
			>
				<div className="self-stretch flex flex-col items-center justify-start gap-[16px_0px]">
					<div className="relative w-10 lg:w-20">
						<img
							className="w-full  object-cover"
							alt=""
							src={`${process.env.PUBLIC_URL + "/glass.svg"}`}
						/>
						<RiVoiceprintFill className="text-[25px] lg:text-[50px] absolute top-[25%] left-[20%]" />
					</div>
					<div className="self-stretch relative leading-[130%] font-extrabold">
						AI Voice
					</div>
					<div className="self-stretch relative text-base md:leading-[150%] text-black-black-60">
						<p className="m-0 text-[8px] md:text-inherit">
							Store your growing investments in our
						</p>
						<p className="m-0 text-[8px] md:text-inherit">
							non-custodial wallet that gives you access to a full suite of DeFi
							services in one place
						</p>
					</div>
				</div>
				<div
					onClick={() => handleChat()}
					className="rounded-3xs text-green-200 overflow-hidden flex flex-row items-center justify-center gap-[0px_6px] text-base text-mediumseagreen mb-4"
				>
					<div className="rounded-3xs overflow-hidden flex flex-row items-center justify-center gap-[0px_6px] cursor-pointer">
						<div className="relative leading-[100%] font-semibold">
							Try chat
						</div>
						<GrFormNextLink className="" />
					</div>
				</div>
			</div>

			<img
				className="absolute top-[30%] md:top-[34%] lg:top-0 -translate-x-2/4 -translate-y-[8%] left-[34%] md:left-[53%] w-[500px] md:w-[600px] md:w-[900px] lg:w-[90%]  object-cover z-50 md:z-0"
				alt=""
				src={`${process.env.PUBLIC_URL + "/iphone-15@2x.png"}`}
			/>

			<div
				id="card-3"
				className="absolute top-[32%] md:top-[17%] -right-6 md:right-6 lg:top-20 lg:right-[10%] rounded-xl bg-[#1A1B23]  w-[200px] h-fit  md:w-[250px] lg:w-[380px] flex flex-col items-center justify-start py-8 px-6 box-border gap-[32px_0px] text-center z-10"
			>
				<div className="self-stretch flex flex-col items-center justify-start gap-[16px_0px]">
					<div className="relative w-10 lg:w-20">
						<img
							className="w-full  object-cover"
							alt=""
							src={`${process.env.PUBLIC_URL + "/glass.svg"}`}
						/>
						<TbDeviceVisionPro className="text-[25px] lg:text-[50px] absolute top-[25%] left-[20%]" />
					</div>
					<div className="self-stretch relative leading-[130%] font-extrabold">
						Gaming
					</div>
					<div className="self-stretch relative text-base md:leading-[150%] text-black-black-60">
						<p className="m-0 text-[8px] md:text-inherit">
							Store your growing investments in our
						</p>
						<p className="m-0 text-[6px] md:text-inherit">
							non-custodial wallet that gives you access to a full suite of DeFi
							services in one place
						</p>
					</div>
				</div>
				<div
					onClick={() => handleChat()}
					className="rounded-3xs text-green-200 overflow-hidden flex flex-row items-center justify-center gap-[0px_6px] text-base text-mediumseagreen mb-4 "
				>
					<div className="rounded-3xs md:overflow-hidden flex flex-row items-center justify-center gap-[0px_6px] cursor-pointer">
						<div className="relative leading-[100%] font-semibold">
							Try chat
						</div>
						<GrFormNextLink className="" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default ChatShowCase;
